import React from "react";
import { message } from "antd";
import { useMutation } from "@apollo/client";
import { UPDATE_CAMPAIGN } from "../../mutations";
import CampaignForm from "./CampaignForm";

const EditCampaign = ({ handleCancel, campaign, handleFinishEdit }) => {
    const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
        onCompleted: (data) => {
            message.success("キャンペーンを編集しました。");
            handleFinishEdit();
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const onFinish = ({
        campaignCategoryId,
        campaignName,
        presentName,
        presentMinPrice,
        couponMinPrice,
        campaignDiscountRate,
        discountType,
    }) => {
        updateCampaign({
            variables: {
                input: {
                    id: campaign.id,
                    campaignCategoryId,
                    campaignName,
                    presentName,
                    presentMinPrice,
                    couponMinPrice,
                    campaignDiscountRate:
                        discountType === "rate"
                            ? String(campaignDiscountRate / 100)
                            : null,
                    campaignDiscountPrice:
                        discountType === "price"
                            ? String(campaignDiscountRate)
                            : null,
                },
            },
        });
    };

    return (
        <CampaignForm
            handleCancel={handleCancel}
            onFinish={onFinish}
            campaign={campaign}
        />
    );
};

export default EditCampaign;
