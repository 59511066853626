import React, { useState } from 'react'
import { Button, Divider, Typography, Modal } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import moment from 'moment'
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { eventStyleGetter } from './CampaignSchedule'

import 'moment/locale/ja'; 
moment.locale('ja', {
    week: {
        dow: 1,
        doy: 1
    }
});
const localizer = momentLocalizer(moment);

const PdfExportButton = ({ events, selectedDate }) => {
  const [ eventForMonth ] = useState(events.filter((event) => moment(event.start).format('YYYY-MM') === moment(selectedDate).format('YYYY-MM')
                                                           || moment(event.end).format('YYYY-MM') === moment(selectedDate).format('YYYY-MM'))
                                                           .sort((a, b) => moment(a.start).isBefore(b.start) ? -1 : moment(a.start).isAfter(b.start) ? 1 : 0))
  const [showCalendar, setShowCalendar] = useState(false);
  const campaingPlanToPdf = async () => {
    let element = document.getElementById("calender_to_pdf");
    let element2 = document.getElementById("campaign-detail-to-pdf");
    const canvas1 = await html2canvas(element)
    const canvas2 = await html2canvas(element2)
    const data = canvas1.toDataURL();
    const data2 = canvas2.toDataURL();
    const pdfExportSetting = {
        content: [
          {
            image: data,
            width: 770,
            height: 545
          },
          {
            image: data2,
            width: 770
          }
        ],
        pageSize: 'A4',
        pageOrientation: 'landscape',
        
      };
      pdfMake.createPdf(pdfExportSetting).download(`${moment(selectedDate).format('YYYY-MM')}_キャンペーン計画.pdf`);
  };
  return (
    <>
        <Button onClick={() => setShowCalendar(true)} icon={<ExportOutlined/>}>
          カレンダー出力
        </Button>
        {showCalendar &&
        <Modal
            title='カレンダーを出力しますか？'
            visible={showCalendar}
            onCancel={() => setShowCalendar(false)}
            onOk={campaingPlanToPdf}
            width={1200}
            height={900}
            bodyStyle={{
              width: '1200px',
              height: '900px',
              maxWidth: '1200px',
              maxHeight: '900px',
              overflow: 'auto',
              padding: '0.5rem'
            }}
        >
            <div id='calender_to_pdf'>
                <Calendar
                    localizer={localizer}
                    views={['month']}
                    events={events}
                    selectable
                    date={selectedDate}
                    endAccessor={(event) => {
                        const endDate = moment(event.end)
                        return endDate.add(1, 'days')
                    }}
                    defaultView='month'
                    style={{ height: '900px', fontSize: '12px', backgroundColor: 'white' }}
                    eventPropGetter={eventStyleGetter}
                    components={{
                        toolbar: ({onNavigate}) => <Typography.Text strong style={{ fontSize: '1rem' }}>
                                                      {`${moment(selectedDate).format('YYYY年M月')}  `}キャンペーンカレンダー
                                                  </Typography.Text>
                    }}
                /> 
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}} id='campaign-detail-to-pdf'>
                <Typography.Text strong style={{ margin: '0.5em', fontSize: '1rem'}}>キャンペーンリスト</Typography.Text>
                <Divider />
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: '.5rem'}}>
                    <Typography.Text strong style={{ margin: '0 10px', width: '150px'}}>日付</Typography.Text>
                    <Typography.Text strong>商品名/キャンペーン名</Typography.Text>
                </div>
                {eventForMonth?.map(event => (
                    <div key={event.id} style={{ display:'flex', flexDirection: 'row'}}>
                        <Typography style={{ margin: '0 10px', width: '150px'}}>{event.formattedRange}</Typography>
                        <Typography>{event.title}</Typography>
                    </div>
                ))}
            </div>
        </Modal>}
    </>
  )
}

export default PdfExportButton