import React, { useMemo, useState} from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_UNGROUPED_SNACK_SHIPMENTS, GET_ALL_ITEMS, GET_UNGROUPED_PREDS, GET_USER_TAGS, GET_ITEM_TAGS, GET_FAVORITES} from "../../queries";
import { Modal, Row, Col, Segmented, Button, Select, Form, DatePicker, Layout, Cascader, Spin, Space, Tag, Tooltip } from "antd";
import { ExportOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { addMonths, format } from "date-fns";
import "./SnackMonthly.less";
import PredictionsChart from "./PredictionsChart";
import moment from "moment";
import * as XLSX from 'xlsx'

const SnackMonthly = () => {
	const [tableGraphMode, setTableGraphMode] = useState("table");
	const [divisions, setDivisions] = useState([]);
	const [selectedDivisions, setSelectedDivisions] = useState([]);
	const [snackShipments, setSnackShipments] = useState([]);
	const [preds, setPreds] = useState([]);	
	const [groupBy, setGroupBy] = useState("day");
	const [selectedTags, setSelectedTags] = useState([]);
	const [filterFavorites, setFilterFavorites] = useState(false);
	
	const [form] = Form.useForm();

	const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
	
	const { data: rawData, loading: allItemsLoading } = useQuery(GET_ALL_ITEMS, { errorPolicy: "all", notifyOnNetworkStatusChange: true });
	
	const { data: tags } = useQuery(GET_USER_TAGS, { variables: { userId: currentUser.id } });

    const {data: itemTags } = useQuery(GET_ITEM_TAGS, {variables: {userId: currentUser.id}, fetchPolicy: 'network-only'});

	const { data: favorites } = useQuery(GET_FAVORITES, { variables: { userId: currentUser.id } });
	
    const formattedTags = useMemo(() => {
        if (tags) {
            return tags.tags.map(tag => {
                return {
                    label: tag.name,
                    value: tag.color,
                    id: tag.id
                }
            })
        }
        return []
    }, [tags])
	
    const allItems = useMemo(() => {
        if (rawData && itemTags) {
            const parsed = JSON.parse(rawData.allItems);
            const tagMap = {};
            itemTags.itemTags.forEach(itemTag => {
                const tagId = itemTag.tag.id;
                if (tagMap[tagId]) {
                    tagMap[tagId].push(itemTag.itemName.id);
                } else {
                    tagMap[tagId] = [itemTag.itemName.id];
                }
            });
			
			// filter based on favorites
			if (filterFavorites) {
				const favoriteIds = favorites?.favorites.map(favorite => parseInt(favorite.itemNameId));
				const filteredItems = parsed.filter(item => favoriteIds.includes(item.id));
				return filteredItems;
			}
			
			// Filter items based on selected tags
			if (selectedTags.length > 0) {
				const filteredItems = parsed.filter(item => {
					return selectedTags.some(tagId => tagMap[tagId]?.includes(item.id.toString()));
				});
				return filteredItems;
			}
			
            return parsed; // Always return parsed items for the Select options
        }
        return [];
    }, [rawData, selectedTags, itemTags, favorites, filterFavorites]);
	
	const prodIdToItemName = useMemo(() => {
		const items = {
			idsToNames: {},
		};
		allItems.forEach((item) => {
			item.item_mappings.forEach((mapping) => {
				items[mapping.prod_id] = `${item.item_name} --- ${item.item_size}`;
				items.idsToNames[item.id] = `${item.item_name} --- ${item.item_size}`;
			});
		});
		return items;
	}, [allItems]);

	const itemNameToProdIds = useMemo(() => {
		const nameToProdIds = {};  // Directly create the mapping object
		
		allItems.forEach((item) => {
			const itemNameWithSize = `${item.item_name} --- ${item.item_size}`;
			
			if (!nameToProdIds[itemNameWithSize]) {
				nameToProdIds[itemNameWithSize] = [];
			}
			item.item_mappings.forEach((mapping) => {
				nameToProdIds[itemNameWithSize].push(mapping.prod_id);
			});
		});
		return nameToProdIds;  // Return the mapping directly
	}, [allItems]);

	const [getSnackShipments, { loading: shipmentsLoading }] = useLazyQuery(GET_UNGROUPED_SNACK_SHIPMENTS, {
		onCompleted: (data) => {
			// each row will have 4 values, salesDepartmentAbbreviation, branchName, primaryAccountingDestinationName, shippingDestinationNameKana
			// department -> branch -> primaryAccountingDestinationName -> shippingDestinationNameKana
			const divisions = {};
			data.snackShipments.forEach((shipment) => {
				const { salesDepartmentAbbreviation, branchName, primaryAccountingDestinationName
					,shippingDestinationNameKana } = shipment;
				if (!divisions[salesDepartmentAbbreviation]) {
					divisions[salesDepartmentAbbreviation] = {};
				}
				if (!divisions[salesDepartmentAbbreviation][branchName]) {
					divisions[salesDepartmentAbbreviation][branchName] = {};
				}
				if(!divisions[salesDepartmentAbbreviation][branchName][primaryAccountingDestinationName]) {
					divisions[salesDepartmentAbbreviation][branchName][primaryAccountingDestinationName] = {};
				}
				shippingDestinationNameKana?.length > 0 && (divisions[salesDepartmentAbbreviation][branchName][primaryAccountingDestinationName][shippingDestinationNameKana] = true);
			});
			
			const divisionsOptions = Object.keys(divisions).map((department) => {
				return {
					label: department,
					value: department,
					children: Object.keys(divisions[department]).map((branch) => {
						return {
							label: branch,
							value: branch,
							children: Object.keys(divisions[department][branch]).map((company) => {
								return {
									label: company,
									value: company,
									children: Object.keys(divisions[department][branch][company]).map((destination) => {
										return {
											label: destination,
											value: destination,
										};
									}),
								};
							}),
						};
					}),
				};
			});
			setDivisions(divisionsOptions);
			setSnackShipments(data.snackShipments);
		},
	});

	const months = useMemo(() => {
		if (snackShipments.length === 0) {
			return new Date();
		}
		const latestMonth = new Date(Math.max(...snackShipments.map((s) => new Date(s.shippingDate).getTime())));

		return [
			// one month before current and 2 after
			addMonths(latestMonth, -1),
			latestMonth,
			addMonths(latestMonth, 1),
			addMonths(latestMonth, 2),
		];
	}, [snackShipments]);

	const [getPreds, { loading: predsLoading }] = useLazyQuery(GET_UNGROUPED_PREDS, {
		onCompleted: (data) => {
		  // parse preds into an array of objects and match with snackShipments
		  const predsArray = [];
		  data.ungroupedPreds.forEach((pred) => {
			const { item_name_id, date, sales_department_abbreviation, branch_name, primary_accounting_destination_name, 
			  shipping_destination_name_kana, count } = pred;
			const itemName = prodIdToItemName.idsToNames[item_name_id];
			const yearMonth = format(new Date(date), 'yyyyMM')
	  
			predsArray.push({
			  itemName,
			  salesDepartmentAbbreviation: sales_department_abbreviation,
			  branchName: branch_name,
			  primaryAccountingDestinationName: primary_accounting_destination_name,
			  shippingDestinationNameKana: shipping_destination_name_kana,
			  shippingDate: date,
			  shippingYearMonth: yearMonth,
			  numberOfCases: count,
			});
		  });
		  setPreds(predsArray);
		},
	  });
	  
	const filteredShipments = useMemo(() => {
		if (selectedDivisions.length === 0) {
			return snackShipments;
		}
		// divisions: array of multiple [department, branch, primaryAccountingDestinationName, shippingDestinationNameKana], can terminate early
		return snackShipments.filter((shipment) => {
			const { salesDepartmentAbbreviation, branchName, primaryAccountingDestinationName, shippingDestinationNameKana } = shipment;
			// if any match, return true
			return selectedDivisions.some((division) => {
				const [department, branch, company, destination] = division;
				return (
					(!department || department === salesDepartmentAbbreviation) &&
					(!branch || branch === branchName) &&
					(!company || company === primaryAccountingDestinationName) && 
					(!destination || destination === shippingDestinationNameKana)
				);
			});
		});
	}, [snackShipments, selectedDivisions]);

	const filteredPreds = useMemo(() => {
		if (selectedDivisions.length === 0) {
			return preds;
		}
		// divisions: array of multiple [department, branch, primaryAccountingDestinationName, shippingDestinationNameKana], can terminate early
		return preds.filter((pred) => {
			const { salesDepartmentAbbreviation, branchName, primaryAccountingDestinationName, shippingDestinationNameKana } = pred;
			// if any match, return true
			return selectedDivisions.some((division) => {
				const [department, branch, company, destination] = division;
				return (
					(!department || department === salesDepartmentAbbreviation) &&
					(!branch || branch === branchName) &&
					(!company || company === primaryAccountingDestinationName) && 
					(!destination || destination === shippingDestinationNameKana)
				);
			});
		});
	}, [preds, selectedDivisions]);

	const items = useMemo(() => {
		const items = {};
		// each item is an object where the keys are the year/month and the value is the sum of the shipments for that month
		filteredShipments.forEach((shipment) => {
			const { productId, shippingYearMonth, numberOfCases } = shipment;
			const itemName = prodIdToItemName[productId];
			if (!items[itemName]) {
				items[itemName] = {};
			}
			const current = items[itemName][shippingYearMonth] || 0;

			items[itemName][shippingYearMonth] = current + numberOfCases;
		});
		return items;
	}, [filteredShipments, prodIdToItemName]);

	const predItems = useMemo(() => {
		const items = {};
		// each item is an object where the keys are the year/month and the value is the sum of the shipments for that month
		filteredPreds.forEach((shipment) => {
			const { itemName, shippingYearMonth, numberOfCases } = shipment;
			if (!items[itemName]) {
				items[itemName] = {};
			}
			const current = items[itemName][shippingYearMonth] || 0;

			items[itemName][shippingYearMonth] = current + numberOfCases;
		});
		return items;
	}, [filteredPreds]);

	const groupByWeekOrMonth = (data, groupBy) => {
		const grouped = {};
		// if grouping by week, data is summed with key as first day of that week
		// if grouping by month, data is summed with key as first day of that month
		if (groupBy === "day") return data;
		
		Object.keys(data).forEach((date) => {
			const dateObj = new Date(date);
			const firstDayOfMonth = moment(dateObj).startOf("month").format("YYYY-MM-DD")
			const firstDayOfWeek = moment(dateObj).startOf("week").format("YYYY-MM-DD")
			const key = groupBy === "week" ? firstDayOfWeek : firstDayOfMonth;
			const current = grouped[key] || 0;
			grouped[key] = current + data[date];
		});
		
		return grouped;	
	}
	
	const chartShipments = useMemo(() => {
		// format needs to be { "2024-01-01": 0, "2024-01-02": 0, ... }
		const data = {};
		filteredShipments.forEach((shipment) => {
			if (!shipment.shippingDate || !shipment.numberOfCases) return;
			const current = data[shipment.shippingDate] || 0;
			data[shipment.shippingDate] = shipment.numberOfCases + current;
		});
		return groupByWeekOrMonth(data, groupBy);
	}, [filteredShipments, groupBy]);

	const chartPreds = useMemo(() => {
		// format needs to be { "2024-01-01": 0, "2024-01-02": 0, ... }
		const data = {};
		filteredPreds.forEach((shipment) => {
			if (!shipment.shippingDate || !shipment.numberOfCases) return;
			const current = data[shipment.shippingDate] || 0;
			data[shipment.shippingDate] = shipment.numberOfCases + current;
		});
		return groupByWeekOrMonth(data, groupBy);
	}, [filteredPreds, groupBy]);

	const handleFetch = (values) => {
		getSnackShipments({
			variables: {
				itemNameIds: values.items,
				// use jp timezone
				dateRange: values.dateRange?.map((d) => d.format("YYYY-MM-DD")),
			},
		});
		getPreds({
			variables: {
				itemNameIds: values.items,
				dateRange: values.dateRange?.map((d) => d.format("YYYY-MM-DD")),
			},
		});
	};
	
	const handleTagChange = (tag, checked) => {
		const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
		setSelectedTags(nextSelectedTags);
	};
	
	const combinedDates = [...new Set([...Object.keys(chartShipments), ...Object.keys(chartPreds)])].sort();
	const csvHeader = ['商品名', '製品コード', '統括部', '支店', '帳合先', '出荷先', '日付', '曜日', '納品数', '予測数'];
	const csvRowsForDateRange = (dateRange, filteredShipments, filteredPreds = {}) => {
		const rows = filteredShipments.map((shipment) => {
			const {
				productId,
				salesDepartmentAbbreviation,
				branchName,
				primaryAccountingDestinationName,
				shippingDestinationNameKana,
				shippingDate,
				numberOfCases
			} = shipment;
	
			const prodName = prodIdToItemName[productId] || 'Unknown Product'; // Lookup product name
			const prodIds = itemNameToProdIds[prodName]; 
			const prodIdsString = Array.isArray(prodIds) ? prodIds.join(', ') : 'Unknown Product Id'; // Join all prod_ids
			const dow = moment(shippingDate).format('ddd'); // Day of the week
			const predCount = filteredPreds[shippingDate]?.[prodName] || 0; // Lookup prediction count for the date and product
			
			return [
				prodName,
				prodIdsString,
				salesDepartmentAbbreviation,
				branchName,
				primaryAccountingDestinationName,
				shippingDestinationNameKana,
				shippingDate,
				dow,
				numberOfCases,
				predCount
			];
		});

		const preRows = filteredPreds.map((pred) => {
			const {
				itemName,
				salesDepartmentAbbreviation,
				branchName,
				primaryAccountingDestinationName,
				shippingDestinationNameKana,
				shippingDate,
				numberOfCases
			} = pred;
			
			const prodIds = itemNameToProdIds[itemName];
			const prodIdsString = Array.isArray(prodIds) ? prodIds.join(', ') : 'Unknown Product Id'; // Join all prod_ids
			const dow = moment(shippingDate).format('ddd'); // Day of the week
			const shipCount = filteredShipments[shippingDate]?.[itemName] || 0; 

			return [
				itemName,
				prodIdsString,
				salesDepartmentAbbreviation,
				branchName,
				primaryAccountingDestinationName,
				shippingDestinationNameKana,
				shippingDate,
				dow,
				shipCount,
				numberOfCases,
			];
		});

		// Combine rows and preRows
		const combinedRows = [...rows, ...preRows];

		// Sort the combined rows by 商品名 (prodName), 日付 (shippingDate), 統括部 (salesDepartmentAbbreviation),
		// 支店 (branchName), 帳合先 (primaryAccountingDestinationName), 出荷先 (shippingDestinationNameKana)
		combinedRows.sort((a, b) => {
			const [
				prodNameA, , salesDepartmentAbbreviationA, branchNameA, primaryAccountingDestinationNameA, shippingDestinationNameKanaA, shippingDateA
			] = a;
			const [
				prodNameB, , salesDepartmentAbbreviationB, branchNameB, primaryAccountingDestinationNameB, shippingDestinationNameKanaB, shippingDateB
			] = b;

			return (
				prodNameA.localeCompare(prodNameB) ||
				new Date(shippingDateA) - new Date(shippingDateB) ||
				salesDepartmentAbbreviationA.localeCompare(salesDepartmentAbbreviationB) ||
				branchNameA.localeCompare(branchNameB) ||
				primaryAccountingDestinationNameA.localeCompare(primaryAccountingDestinationNameB) ||
				shippingDestinationNameKanaA.localeCompare(shippingDestinationNameKanaB)
			);
		});

		return combinedRows; // Return the sorted rows
	};
	
	const generateCSVData = (header, rows) => [header, ...rows];
	const handleSnackExport = () => {
		const workbook = XLSX.utils.book_new();
		const csvData = generateCSVData(csvHeader, csvRowsForDateRange(combinedDates, filteredShipments, filteredPreds));

		// Check if the number of rows exceeds the limit
		if (csvData.length > 999999) {
			Modal.warning({
				title: 'エクセル出力エラー',
				content: 'データ数が100万行を超えました。フィルターの調整をしてデータ数を減らしてください。',
			});
			return;
		}
		const worksheet = XLSX.utils.aoa_to_sheet(csvData, { cellDates: true });
		XLSX.utils.book_append_sheet(workbook, worksheet);
		XLSX.writeFileXLSX(workbook, 'snack_shipments.xlsx')
	}

	const rowItemStyle = { marginTop: ".5rem"};
	const rowGutter = [12, 12];

	const dataExists = Object.keys(items).filter((i) => i !== "idsToNames").length > 0;

	return (
		<Layout style={{ padding: 24, background: "#fff" }}>
			<Row justify="end" gutter={rowGutter}>
				<Col>
					<Form form={form}>
						<Form.Item name="groupBy" initialValue="day">
							<Segmented
								options={[
									{
										label: "日",
										value: "day",
									},
									{
										label: "週",
										value: "week",
									},
									{
										label: "月",
										value: "month",
									},
								]}
								
								disabled={tableGraphMode === "table"}
								onChange={setGroupBy}
							/>
						</Form.Item>
					</Form>
				</Col>
				<Col>
					<Segmented
						options={[
							{ label: "テーブル", value: "table" },
							{ label: "グラフ", value: "graph" },
						]}
						onChange={(v) => setTableGraphMode(v)}
					/>
				</Col>
				<Col>
					<Button
						icon={<ExportOutlined />}
						onClick={handleSnackExport}
						disabled={combinedDates.length === 0}
						>
						
						XLSX出力
					</Button>
				</Col>
			</Row>
			<Row gutter={rowGutter}>
				<Form
					form={form}
					layout="inline"
					style={{ minWidth: "100%", display: "flex", alignItems: "center" }}
					onFinish={(values) => {
						handleFetch(values);
					}}>
					<Form.Item label="商品" name="items" style={rowItemStyle}>
						<Select
							showSearch
							mode="multiple"
							filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							options={allItems.map((item) => ({ value: item.id, label: `${item.item_name} --- ${item.item_size}` }))}
							style={{ width: "35rem" }}
							notFoundContent={allItemsLoading ? <Spin size="small" /> : (allItems.length === 0 ? "No items found" : null)}
						/>
					</Form.Item>
					<Form.Item>
						<Tooltip placement="top" title='お気に入り商品のみを表示する'>
							<Button icon={filterFavorites ? <StarFilled/> : <StarOutlined/>} onClick={() => setFilterFavorites(prev => !prev)} shape="circle" />
						</Tooltip>
					</Form.Item>
					<Form.Item label="営業部" style={rowItemStyle}>
						<Cascader
							disabled={divisions.length === 0}
							multiple
							style={{ minWidth: "20rem" }}
							options={divisions || []}
							onChange={setSelectedDivisions}
						/>
					</Form.Item>
					<Form.Item label="表示期間" name="dateRange" style={rowItemStyle}>
						<DatePicker.RangePicker disabled={tableGraphMode === "table"} onChange={() => form.submit()} />
					</Form.Item>
				</Form>
			</Row>
			<Row gutter={rowGutter} style={{ marginTop: ".5rem" }} justify="start">
				<Col>
					{formattedTags.length > 0 && (
						<Row style={{ marginTop: ".5rem" }}>
							<div style={{ flex: 3, display: "flex", alignItems: "center" }} align="left">
								<span style={{ marginRight: 8 }}>タグ:</span>
								<Space size={[0, 8]} wrap>
									{formattedTags.map((tag) => (
										<Tag.CheckableTag
											style={{
												backgroundColor: selectedTags.includes(tag.id) ? tag.value : "white",
												border: !selectedTags.includes(tag.id) && "1px solid " + tag.value,
												color: selectedTags.includes(tag.id) ? "white" : tag.value,
											}}
											key={tag.id}
											checked={selectedTags.includes(tag.id)}
											onChange={(checked) => handleTagChange(tag.id, checked)}>
											{tag.label}
										</Tag.CheckableTag>
									))}
								</Space>
							</div>
						</Row>
					)}
				</Col>
				
			</Row>
			<Row justify="end">
				<Col>
					<Button type="primary" onClick={() => form.submit()}>
						{" "}
						アップデート
					</Button>
				</Col>
			</Row>
			<Row style={{ marginTop: "1rem" }}>
				{shipmentsLoading || predsLoading ? (
					<Spin size="large" style={{ margin: "0 auto" }} />
				) : tableGraphMode === "table" ? (
					<>
						{dataExists && (
							<table style={{ border: "1px solid lightgrey", width: "100%" }}>
								<thead className="tableGrey">
									<tr>		
										<th className="leftBorder botBorder" rowSpan="2" style={{ width: "15%"}}>商品名</th>
										<th className="leftBorder botBorder" rowSpan="2" colSpan="10">商品サイズ</th>
										{months.map((month) => (
											<th key={month} className="tableMonths leftBorder" colSpan="3">{`${format(month, "yyyy-MM")}月`}</th>
										))}
									</tr>
									<tr>
										<th className="leftBorder botBorder" colSpan="3">実績</th>
										<th className="leftBorder botBorder" colSpan="3">実績 / 予測</th>
										<th className="leftBorder botBorder" colSpan="3">予測</th>
										<th className="leftBorder botBorder" colSpan="3">予測</th>
									</tr>
								</thead>
								<tbody className="tableWhite">
									{Object.keys(items)
										.sort((a, b) => a.localeCompare(b))
										.map((item) => {
											const [itemName, itemSize] = item.includes(' --- ') ? item.split(' --- ') : [item, ''];
											return (
												<tr key={item}>
													<td className="tableGrey botBorder">{itemName}</td>
													<td className="tableGrey leftBorder botBorder" colSpan="10">{itemSize}</td>
													{months.map((month, index) => {
														const monthKey = format(month, "yyyyMM");
														const actualValue = items[item][monthKey] || 0;
														const predsValue = predItems[item]?.[monthKey] || 0;
														let monthValue = "";
														if (index === 0) {
															monthValue = actualValue;
														}
														if (index === 1) {
															const totalValue = actualValue + predsValue;
															monthValue = (
																<>
																	{actualValue} / {predsValue}
																	<br />
																	({totalValue})
																</>
															);
														}
														if (index >= 2) {
															monthValue = predsValue;
														}
														return (
															<td
																key={item + month}
																className="leftBorder botBorder"
																style={{ textAlign: "center"}}
																colSpan="3">
																{monthValue}
															</td>
														);
													})}
												</tr>
											);
										})}
								</tbody>
							</table>
						)}
					</>
				) : (
					<div style={{ width: "100%", height: "70vh" }}>
						<PredictionsChart
							dateRange={form.getFieldValue("dateRange")}
							predictions={chartPreds}
							nouhins={chartShipments}
							tickValues={form.getFieldValue("groupBy")}
						/>
					</div>
				)}
			</Row>
		</Layout>
	);
};

export default SnackMonthly;
