import { isWithinSellingPeriod } from './date'
import { isSearchMatch } from './isSearchMatch'

export const filterRowsByQueryAndFilterParams = (rows, searchText, filterFavorites, dateRange, selectedTags= []) => {
    let filtered = rows;

    if (filterFavorites) {
        filtered = filtered.filter(row => row.favorite)
    }

    if (searchText) {
        const phrases = searchText.split(' ')

        const sizePhrase = phrases.find(e => e.includes("size:"))
        if (sizePhrase) {
            phrases.splice(phrases.indexOf(sizePhrase), 1)
            let size = sizePhrase.split(':')[1].trim()
            if (size === "バラ") size = ""
            filtered = filtered.filter(row => size === row.size)
        }

        const recombined = phrases.join(' ')
        filtered = filtered.filter(row => isSearchMatch(recombined, row.name) || row.productCodes?.map(pc => ' ' + pc).toString().includes(parseInt(searchText)))
    }

    if (dateRange) {
        filtered = filtered.filter(row => {
            if (!row.sellingPeriod) return false
            return isWithinSellingPeriod(dateRange[0], dateRange[1], row.sellingPeriod?.start, row.sellingPeriod?.end)
        });
    }
    if (selectedTags.length > 0) {
        filtered = filtered.filter(row => {
            if (selectedTags.length > 1 && row.tags.length === selectedTags.length) {
                const rowTagIds = row.tags.map(tag => tag.tagId)
                return selectedTags.every(tagId => rowTagIds.includes(tagId))
            } else if (selectedTags.length === 1) {
                return row.tags.some(tag => tag.tagId === selectedTags[0])
            } else {
                return false
            }
        })
    }
    
    // sort the filtered rows so that any exact matches are at the top
    filtered.sort((a, b) => {
        if (searchText) {
            const aMatch = a.name === searchText
            const bMatch = b.name === searchText
            if (aMatch && !bMatch) return -1
            if (!aMatch && bMatch) return 1
        }
        return 0
    })

    return filtered
}
