import React, { useState, useEffect } from "react";
import {
    Layout,
    Spin,
    Select,
    Space,
    Button,
    Typography,
    Input,
    InputNumber,
    Form,
    DatePicker,
    Checkbox,
    Row,
    Col,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { GET_CAMPAIGN_CATEGORIES, GET_ALL_ITEMS } from "../../queries";

const CampaignForm = ({ handleCancel, campaign, onFinish }) => {
    const { data: campaignCategoriesData, loading: campaignCategoriesLoading } = useQuery(GET_CAMPAIGN_CATEGORIES);
    const { data: rawData, loading: itemsLoading } = useQuery(GET_ALL_ITEMS);
    const [form] = Form.useForm();
    const formCampaignLayout = {
        labelCol: { 
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 }
        },
        layout: "vertical",
        labelAlign: "left",
    };

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([[]]);
    const [selectAll, setSelectAll] = useState({});

    useEffect(() => {
        if (rawData) {
            const allProducts = JSON.parse(rawData.allItems); // Adjust according to actual data structure
            setProducts(allProducts);
            setFilteredProducts(allProducts); // Initialize filteredProducts
        }
    }, [rawData]);

    const filterProducts = (range, index) => {
        if (!range) {
            setFilteredProducts(prev => {
                const newFiltered = [...prev];
                newFiltered[index] = products;
                return newFiltered;
            });
            return;
        }
        const start = range[0].format().slice(0, 10);
        const end = range[1].format().slice(0, 10);
        const filtered = products.filter(e => {
            const cycles = e.item_selling_cycles;
            const status = e.user_defined_status ? e.user_defined_status : e.status;
            if (status === 'Regular') {
                return true;
            }
            return cycles.some(cycle => cycle.selling_start_dt.slice(0, 10) <= end && cycle.selling_end_dt.slice(0, 10) >= start);
        });
        setFilteredProducts(prev => {
            const newFiltered = [...prev];
            newFiltered[index] = filtered;
            return newFiltered;
        });
    }

    const onSelectAllChange = (fieldIndex, checked) => {
        const newSelectAll = { ...selectAll, [fieldIndex]: checked };
        setSelectAll(newSelectAll);
        if (checked) {
            form.setFieldsValue({
                campaignPlans: form.getFieldValue('campaignPlans').map((item, index) => {
                    if (index === fieldIndex) {
                        return { ...item, items: products.map(p => p.id) };
                    }
                    return item;
                }),
            });
        } else {
            form.setFieldsValue({
                campaignPlans: form.getFieldValue('campaignPlans').map((item, index) => {
                    if (index === fieldIndex) {
                        return { ...item, items: [] };
                    }
                    return item;
                }),
            });
        }
    };

    if (campaignCategoriesLoading || itemsLoading) {
        return <Spin />;
    }

    const initialValues = campaign && {
        ...campaign,
        campaignDiscountRate:
            parseFloat(campaign.campaignDiscountRate) ||
            campaign.campaignDiscountPrice,
    };

    const department = JSON.parse(localStorage.getItem('currentUser'))?.department || 'sweets';

    return (
        <>
            <Layout style={{ padding: 0, background: "#fff" }}>
                <Typography.Title level={4}>キャンペーン内容</Typography.Title>
            </Layout>
            <Layout style={{ padding: 24, background: "#fff" }}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={initialValues}
                    {...formCampaignLayout}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="カテゴリー"
                                name="campaignCategoryId"
                                required
                                rules={[
                                    { required: true, message: "カテゴリーが必要です。" },
                                ]}
                            >
                                <Select showSearch placeholder="選択してください">
                                    {campaignCategoriesData.campaignCategories.edges.map(
                                        (campaignCategory) => (
                                            <Select.Option
                                                data-testid="campaign-category"
                                                key={campaignCategory.node.id}
                                                value={campaignCategory.node.id}
                                            >
                                                {
                                                    campaignCategory.node
                                                        .campaignCategoryName
                                                }
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="キャンペーン名"
                                name="campaignName"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: "キャンペーン名が必要です。",
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder="キャンペーン名" />
                            </Form.Item>
                            <Form.Item label="景品名" name="presentName">
                                <Input placeholder="景品名" />
                            </Form.Item>
                            { department === "sweets" && <>
                                <Form.Item label="景品最低価格" name="presentMinPrice">
                                    <InputNumber
                                        defaultValue={campaign?.presentMinPrice}
                                        placeholder="価格"
                                        formatter={(value) => `${value} 円`}
                                    />
                                </Form.Item>
                                <Form.Item label="クーポン最低価格" name="couponMinPrice">
                                    <InputNumber
                                        placeholder="価格"
                                        formatter={(value) => `${value} 円`}
                                    />
                                </Form.Item>
                            </>}
                            <Form.Item label="キャンペーン割引">
                                <Space>
                                    <Form.Item name="campaignDiscountRate">
                                        <Input
                                            type="number"
                                            placeholder="価格または％"
                                            style={{ maxWidth: "9rem" }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="discountType"
                                        initialValue="price"
                                        style={{ maxWidth: "4rem" }}
                                    >
                                        <Select>
                                            <Select.Option value="rate">
                                                %
                                            </Select.Option>
                                            <Select.Option value="price">
                                                円
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Space>
                            </Form.Item>
                        </Col>
                        {!campaign && (
                        <Col span={12}>
                            <Form.List name="campaignPlans" >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Space key={`${field.key}-${index}`} align="baseline" direction="vertical" >
                                                <Form.Item
                                                    {...field}
                                                    label="キャンペーン期間"
                                                    name={[field.name, 'campaignRange']}
                                                    key={`cr-${field.key}-${index}`}
                                                >
                                                    <DatePicker.RangePicker 
                                                        onChange={(value) => filterProducts(value, index)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    label="商品名"
                                                    name={[field.name, 'items']}
                                                    style={{ minWidth: "400px" }}
                                                    key={`items-${field.key}-${index}`}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="入力してください"
                                                        disabled={selectAll[index]} // Use specific item's select all state
                                                        style={{ minWidth: "400px" }}
                                                    >
                                                        {Array.isArray(filteredProducts[index]) ? filteredProducts[index].map(product => (
                                                            <Select.Option key={product.id} value={product.id} style={{ minWidth: "400px" }}>
                                                                {product.item_name}{product.item_size ? " - " + product.item_size : ""}
                                                                {product.item_mappings?.map(im => " " + im.prod_id).join(', ')}
                                                            </Select.Option>
                                                        )) : null}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="selectAllProducts" key={`selectAllProducts-${field.key}-${index}`}>
                                                    <Checkbox
                                                        checked={selectAll[index] || false}
                                                        onChange={(e) => onSelectAllChange(index, e.target.checked)}
                                                    >
                                                        全ての商品を選択する
                                                    </Checkbox>
                                                </Form.Item>
                                                <Button onClick={() => remove(field.name)} type="danger">
                                                    キャンペーン期間削除
                                                </Button>
                                            </Space>
                                        ))}
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            キャンペーン期間と商品名を追加する
                                        </Button>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                        )}
                    </Row>
                    <Form.Item colon={false} label=" ">
                        <Space>
                            <Button type="primary" htmlType="submit">
                                {campaign ? "変更する" : "追加する"}
                            </Button>
                            <Button onClick={handleCancel}>キャンセル</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Layout>
        </>
    );
};

export default CampaignForm;

