import React, { useState, useEffect, useMemo } from 'react';
import {
	Layout,
    Space,
    Radio,
    Checkbox,
    Button,
    Input,
    InputNumber,
    Typography,
    DatePicker,
    Spin,
    message,
    Form,
    Modal
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_ITEM } from '../../mutations';
import { GET_ITEM } from '../../queries';
import UploadButton from './UploadButton';
import moment from 'moment';

const EditItem = ({ handleFinishEdit, itemNameId }) => {
    const { data, loading } = useQuery(GET_ITEM, { variables: { id: itemNameId }, fetchPolicy: 'no-cache'});
    const [itemImgFile, setItemImgFile] = useState('')
    const [form] = Form.useForm();
    const [dupeError, setDupeError] = useState(false)
    const [box, setBox] = useState()
    const [modalOpen, setModalOpen] = useState(false)

    const [updateItem] = useMutation(UPDATE_ITEM, {
        onCompleted: (data) => {
            message.success('商品を編集しました。');
            form.resetFields();
            handleFinishEdit();
        },
        onError: (error) => {
            console.log(error);
            message.error('商品の編集に失敗しました。', 5);
        }
    });

    const currentUser = useMemo(() => {
        return JSON.parse(localStorage.getItem('currentUser'))
    }, [])

    const onFinish = ()  => {
        const { itemName, itemMappings, itemDetail, itemSellingCycles, itemSize, boxSize, regular } = form.getFieldsValue();
        const userDefinedStatus = regular ? 'Regular' : null;
        let inputVariables = {
            itemName: { id: initialValues.id, itemName, itemSize: itemSize === "箱" ? boxSize : itemSize, userDefinedStatus },
            itemProfile: {},
            itemSellingCycles: {},
            itemMappings: {},
        };

        if (itemSellingCycles.length > 0) {
            inputVariables.itemSellingCycles = itemSellingCycles.map(sc => {
                return {
                    regular_price: parseInt(sc.price),
                    selling_start_dt: sc.dates[0].format('YYYY-MM-DD'),
                    selling_end_dt: sc.dates[1].format('YYYY-MM-DD')
                }
            })
        }
        
        if (itemMappings) {
            const seen = {}
            setDupeError(false)
            let dupe = false
            itemMappings.forEach(e => {
                const concatenated = e.prod_id.toString() + (e.prod_name || "")
                if (seen[e.prod_id] === concatenated) {
                    dupe = true
                } else {
                    seen[e.prod_id] = concatenated
                }
            })
            if (dupe) {
                setDupeError(true)
                message.error('製品コードが重複しています。', 5);
                return
            }
            inputVariables.itemMappings = itemMappings
        }
        if (itemDetail) {
            inputVariables.itemProfile.itemDetail = itemDetail
        }
        if (itemImgFile) {
            inputVariables.itemProfile.itemImgFile = itemImgFile
        }

        updateItem({ variables: { input: inputVariables }});
    };
    
    useEffect(() => {
        window.history.pushState(null, null, document.URL);
        window.addEventListener('popstate', event => {
            handleFinishEdit();
        });
    }, [handleFinishEdit]);

    useEffect(() => {
        if(data) {
            if(data.item.item_names[0].item_size > 0) { // I hope this will always correctly evaluate if it's a non-zero number 
                setBox(true)
            }
        }
    }, [data])

    if (loading) {
        return <Spin />
    }

    const formItemLayout = {
        labelCol:  {
            xs: { span: 24 },
            sm: { span: 24 },
            md:  { span: 24 },
            lg:  { span: 6 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md:  { span: 24 },
            lg:  { span: 8 }
        },
        layout: 'horizontal',
        labelAlign: 'right'
    };

    const options = [
        { label: "バラ", value: "" },
        { label: "SS", value: "SS" },
        { label: "S", value: "S" },
        { label: "M", value: "M" },
        { label: "L", value: "L" },
        { label: "XL", value: "XL" },
        { label: "箱", value: "箱" },
    ];

    let editItemName;
    let editItemMappings;
    let editItemProfiles;
    let editItemSellingCycles;
    let initialValues;
    if (data) {
        editItemName = data.item.item_names[0]
        if (editItemName.item_size === null) {
            editItemName.item_size = ""
        }
        editItemMappings = data.item.item_mappings
        editItemProfiles = data.item.item_profiles[0]
        editItemSellingCycles = data.item.item_selling_cycles.map(sc => {
            return { price: sc.regular_price, dates: [moment(sc.selling_start_dt), moment(sc.selling_end_dt)] }
        })
        initialValues = {
            id: editItemName.id,
            downloadUrl: data.item.download_url,
            itemName: editItemName.item_name,
            itemSize: isNaN(editItemName.item_size) || editItemName.item_size === "" ? editItemName.item_size : '箱',
            regular: editItemName.user_defined_status === "Regular" || editItemName.status === "Regular",
            itemDetail: editItemProfiles?.item_detail,
            itemImgFile: editItemProfiles?.item_img_file,
            regularPrice: editItemSellingCycles?.regular_price,
            itemMappings: editItemMappings,
            itemSellingCycles: editItemSellingCycles
        }
        if (initialValues.itemSize === '箱') {
            initialValues.boxSize = editItemName.item_size
        }
    }

    return <>
        <Modal visible={modalOpen} title="商品内容の変更" okText="変更する" cancelText="キャンセル" onOk={onFinish} onCancel={() => setModalOpen(false)}>
            商品内容を変更します。
            よろしいですか。
        </Modal>
        <Layout style={{ padding: '24px 24px 0px 24px', background: '#fff' }}>
            <Typography.Title level={4}>商品内容</Typography.Title>
        </Layout>
        <Layout style={{ padding: 24, background: '#fff' }}>
            <Form
                form={form}
                onFinish={e => setModalOpen(true)}
                initialValues={initialValues}
                {...formItemLayout}
            >
                <Form.Item label='商品名' name='itemName' 
                    rules={[
                        { required: true, message: '商品名が必要です。' }, 
                        { whitespace: true, message: '商品名が必要です。' }, 
                    ]}
                    required>
                    <Input placeholder="商品名" />
                </Form.Item>
                <Form.Item label='商品説明' name='itemDetail' required rules={[{ max: 1000, required: true, message: '商品説明は１０００文字以下である必要があります。' }]}>
                    <Input.TextArea placeholder="商品の説明をわかりやすく書いてください。 最大 1000文字入力可能です。"/>
                </Form.Item>
				<Form.Item label='製品' name='itemMappings' rules={[{required: true, message: '製品が必要です。'}]} {...dupeError && {validateStatus: "error"}} dependencies={['itemMappings', 0]}>
					<Form.List name="itemMappings">
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, fieldKey, ...restField }) => (
									<Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
										<Form.Item
											{...restField}
											name={[name, 'prod_id']}
											fieldKey={[fieldKey, 'prod_id']}
											rules={[{ required: true, message: '製品コードが必要です。' }]}
										>
											<InputNumber
                                                formatter={value => Math.abs(parseInt(value)) || null}
                                                placeholder="製品コード"
                                                type="number"
                                            />
										</Form.Item>
										<Form.Item
											{...restField}
											name={[name, 'prod_name']}
											fieldKey={[fieldKey, 'prod_name']}
											rules={[{ required: true, message: '製品名(ｶﾅ)が必要です。' },
                                            {type: 'string', pattern: /^[\uff00-\uff9fa-zA-Z0-9\][&'&’.()-]*$/, message: '製品名は半角カタカナか英数字のみ入力可能です。'}]}
										>
											<Input placeholder="製品名" />
										</Form.Item>
										<Form.Item
											{...restField}
											name={[name, 'product_category_id']}
											fieldKey={[fieldKey, 'product_category_id']}
											rules={[{ required: true, message: '製品カテゴリーが必要です。' }]}
										>
											<InputNumber
                                                formatter={value => Math.abs(parseInt(value)) || null}
                                                placeholder="製品カテゴリー"
                                                type="number"
                                                style={{ width: 120 }}
                                            />
										</Form.Item>
										<MinusCircleOutlined onClick={() => remove(name)} />
									</Space>
								))}
								<Form.Item>
									<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
										製品を追加する
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Form.Item>
                <Form.Item label='販売期間 / 価格（税込）' name='itemSellingCycles' rules={[{ required: true, message: '販売期間と価格が必要です。' }]} dependencies={['itemSellingCycles', 0]}>
                    <Form.List name='itemSellingCycles'>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Space key={key} align="start">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'dates']}
                                            fieldKey={[fieldKey, 'dates']}
                                            rules={[{ required: true, message: '日付が必要です。' }]}
                                        >
                                            <DatePicker.RangePicker />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'price']}
                                            fieldKey={[fieldKey, 'price']}
                                            rules={[{ required: true, message: '価格が必要です。' }]}
                                        >
                                            <Input
                                                type='number'
                                                placeholder="価格" min={0}
                                                step='any' addonAfter='円'
                                                style={{ width: 130 }}
                                            />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} style={{ marginTop: 8 }} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        販売期間と価格を追加する
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item label="定番商品" name='regular' valuePropName="checked">
                    <Checkbox defaultChecked></Checkbox>
                </Form.Item>
                {currentUser?.department === 'sweets' && (
                    <>
                        <Form.Item label='サイズ' name="itemSize">
                            <Radio.Group options={options} onChange={e => e.target.value === "箱" ? setBox(true) : setBox(false)} />
                        </Form.Item>
                        <Form.Item label='個数' name="boxSize">
                                <Input type='number' min={0} addonAfter='個' placeholder="個数" style={{maxWidth: "8rem"}} disabled={!box}/>
                        </Form.Item> 
                    </>
                )}
                {currentUser?.department === 'snacks' && (
                    <>
                        <Form.Item label='サイズ' name="itemSize">
                            <Typography.Text>{editItemName?.item_size}</Typography.Text>
                        </Form.Item>
                    </>
                )}     
                <Form.Item data-testid='add-image-btn' label='商品画像(ファイル形式: jpeg/pngのみ)' required>
                    <UploadButton
                        currentImgUrl={initialValues?.downloadUrl}
                        setItemImgFile={setItemImgFile}
                    />
                </Form.Item>
                <Form.Item colon={false} label=' '>
                    <Space>
                        <Button
                            type='primary' htmlType='submit' data-testid='submit-edit-item'
                        >
                            変更する
                        </Button>
                        <Button onClick={handleFinishEdit}>キャンセル</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Layout>
    </>
};

export default EditItem;
