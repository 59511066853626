import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message } from 'antd';
import { DELETE_CALENDAR_EVENTS } from '../../../mutations';

const DeleteSpecialEventButton = ({ selectedSpecialEventDay, handleCompleted }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [deleteCalendarEvents] = useMutation(DELETE_CALENDAR_EVENTS, {
        onCompleted: () => handleCompleted(),
        onError: (error) => {
            console.log(error);
        }
    });

    const showPopconfirm = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        deleteCalendarEvents({ variables: { input: { id: selectedSpecialEventDay.id } } })
        .then(() => {
            setConfirmLoading(false);
            setVisible(false);
            message.success('選択したイベントを削除しました。');
        })
        .catch(() => {
            setConfirmLoading(false);
            setVisible(false);
            message.error('選択したイベントを削除できませんでした。');
        });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Popconfirm
            title='削除してもよろしいですか。'
            cancelText='キャンセル'
            okText='削除する'
            visible={visible}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
            placement='topRight'
        >
            <Button
                onClick={showPopconfirm}
                icon={<DeleteOutlined/>}
            />
        </Popconfirm>
    );
};

export default DeleteSpecialEventButton;
