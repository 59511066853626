import React, { useState, useEffect } from 'react';
import {
    Layout,
    Button,
    Input,
    Typography,
    Row,
    Col,
    Spin,
    Table,
    Space
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import EditCampaign from '../AddMenu/EditCampaign';
import AddNewCampaign from '../AddMenu/AddNewCampaign';
import DeleteCampaignsButton from './DeleteCampaignsButton';
import { GET_CAMPAIGNS } from '../../queries';
import moment from 'moment'

const Campaigns = () => {
    const [searchText, setSearchText] = useState('');
    const [addingCampaign, setAddingCampaign] = useState(false);
    const [editingCampaign, setEditingCampaign] = useState(false);
    const [campaignId, setCampaignId] = useState(null);
    const { data, loading, refetch } = useQuery(GET_CAMPAIGNS);
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(parseInt(localStorage.getItem('campaignManagementPage')) || 1);

    useEffect(() => {
        if (data) {
            const parsedRows = data.campaigns.map(
                campaign => ({
                    key: campaign.id,
                    id: campaign.id,
                    campaignName: campaign.campaignName,
                    campaignCategory: campaign.campaignCategory,
                    campaignCategoryId: campaign.campaignCategory && campaign.campaignCategory.id,
                    campaignCategoryName: campaign.campaignCategory && campaign.campaignCategory.campaignCategoryName,
                    category: campaign.campaignCategory && campaign.campaignCategory.campaignCategoryName,
                    presentName: campaign.presentName,
                    presentMinPrice: campaign.presentMinPrice,
                    couponMinPrice: campaign.couponMinPrice,
                    campaignDiscountPrice: campaign.campaignDiscountPrice,
                    campaignDiscountRate: campaign.campaignDiscountRate * 100 || null,
                    discountType: (campaign.campaignDiscountPrice && 'price') || (campaign.campaignDiscountRate && 'rate'),
                    updatedAt: campaign.updatedAt,
                    updatedBy: campaign.updatedBy
                })
            );
            setRows(parsedRows);
            setOriginalRows(parsedRows);
        }
    }, [data]);

    let columns = [
        {
            title: <Typography.Text strong>キャンペーン名</Typography.Text>,
            dataIndex: 'campaignName',
            width: 200
        }, {
            title: <Typography.Text strong>カテゴリー</Typography.Text>,
            dataIndex: 'category',
            width: 150
        }, {
            title: <Typography.Text strong>景品名</Typography.Text>,
            dataIndex: 'presentName',
            width: 150
        }, {
            title: <Typography.Text strong>景品最低価格</Typography.Text>,
            dataIndex: 'presentMinPrice',
            width: 100,
            align: "right",
            render: x =>
                x ? <p>{`￥${x}`}</p> : null
            ,
        }, {
            title: <Typography.Text strong>クーポン最低価格</Typography.Text>,
            dataIndex: 'couponMinPrice',
            width: 100,
            align: "right",
            render: x =>
                x ? <p>{`￥${x}`}</p> : null
            ,
        }, {
            title: <Typography.Text strong>キャンペーン割引</Typography.Text>,
            dataIndex: 'campaignDiscountPrice',
            render: x =>
                x ? <p>{`${x}円`}</p> : null
            ,
            width: 100,
            align: "right"
        }, {
            title: <Typography.Text strong>キャンペーン割引率</Typography.Text>,
            dataIndex: 'campaignDiscountRate',
            render: x => 
                x ? <p>{`${x}%`}</p> : null
            ,
            width: 100,
            align: "center"
        }, {
            title: <Typography.Text strong>編集者</Typography.Text>,
            dataIndex: 'updatedBy',
            width: 150
        }, {
            title: <Typography.Text strong>追加・変更日時</Typography.Text>,
            dataIndex: 'updatedAt',
            align: 'right',
            sorter: (a, b) => {
                const dateA = a['updatedAt'] ? new Date(a['updatedAt']) : new Date(0);
                const dateB = b['updatedAt'] ? new Date(b['updatedAt']) : new Date(0);
                return dateA < dateB ? 1 : -1;
            },
            render: x =>
                x ? <div>
                    <div>{moment(x).format('YYYY-MM-DD')}</div>
                    <div>{moment(x).format('a h:mm')}</div>
                </div> : null,
            width: 150
        },
        {
            title: '',
            fixed: 'right',
            render: (text, rowContent) => {
                return <div style={{ display: 'flex' }}>
                    <Button type='link' onClick={() => {
                        setEditingCampaign(true);
                        setCampaignId(rowContent.key);
                    }}>編集</Button>
                    <DeleteCampaignsButton
                    selectedCampaignIds={rowContent.key}
                    handleCompleted={() => { refetch(); }} />
                </div>
            }

            ,
            width: 100
        }
    ];

    const department = JSON.parse(localStorage.getItem('currentUser'))?.department || 'sweets';

    if (department === 'snacks') {
        columns = columns.filter(column => !['presentMinPrice', 'couponMinPrice'].includes(column.dataIndex))
    }

    const handleSearch = e => {
        const { value } = e.target;
        setSearchText(value);
        if (value !== '') {
            setRows(originalRows.filter(row => row.campaignName.toLowerCase().includes(value.toLowerCase())));
        } else {
            setRows(originalRows);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem('campaignManagementPage', page);
    };

    return <Layout style={{ padding: 24, background: '#fff' }}>
        {loading ?
            <Spin /> : addingCampaign ?
                <AddNewCampaign
                    handleCancel={() => setAddingCampaign(false)}
                    handleFinishAdd={() => {
                        setAddingCampaign(false)
                        refetch()
                    }}
                />
                : editingCampaign ?
                    <EditCampaign
                        handleCancel={() => setEditingCampaign(false)}
                        handleFinishEdit={() => {
                            setEditingCampaign(false);
                            refetch()
                        }}
                        campaign={rows.find(row => row.key === campaignId)}
                    /> : <><Row gutter={[16, 8]}>
                        <Col>
                            <Input.Search style={{ width: '600px' }} value={searchText} onChange={handleSearch} allowClear placeholder="検索" />
                        </Col>
                        <Col style={{ flex: 1 }} align='right'>
                            <Space direction='horizontal'>
                                <Button type='primary' onClick={() => { setAddingCampaign(true) }} icon={<PlusCircleOutlined />}>
                                    キャンペーン追加
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <br/>
                        <Table
                            columns={columns}
                            dataSource={rows}
                            scroll={{ x: 2500 }}
                            pagination={{ defaultPageSize: 8, current: currentPage, onChange: handlePageChange }}
                        />
                    </>
        }
    </Layout>
};

export default Campaigns;
