import React, { useState } from 'react';
import { Button, Popconfirm, Typography, Input } from 'antd';
import { QuestionCircleOutlined, EditOutlined } from '@ant-design/icons';
import propTypes from 'prop-types';

const EmailEditButton = ({ userId, email, updateUser}) => {
    const [editing, setEditing] = useState(false);
    const [newEmail, setNewEmail] = useState(email); 

    const handleEdit = () => {
        // Toggle editing state when the edit button is clicked
        setEditing(true);
    };

    const handleConfirm = () => {
        updateUser({ variables: { input: { id: userId, email: newEmail } } });
    };

    const handleCancelEdit = () => {
        // Cancel editing and reset the email value to the original value
        setEditing(false);
        setNewEmail(email);
    };

    return <>
        <Popconfirm
            title="メールアドレスを変更しますか。"
            icon={<QuestionCircleOutlined />}
            okText='変更する'
            cancelText='キャンセル'
            onConfirm={handleConfirm}
            onCancel={handleCancelEdit}
            visible={editing}
        >
            {editing ? (
                // Render input field if editing is true
                <Input
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    autoFocus // Focus on input field when editing starts
                />
            ) : (
                // Render edit button and email text if not editing
                <>
                    <Typography.Text>{email}</Typography.Text>
                    <Button type='link' onClick={handleEdit}>
                        <EditOutlined />
                    </Button>
                </>
            )}
        </Popconfirm>
    </>;
};

EmailEditButton.propTypes = {
    userId: propTypes.string,
    email: propTypes.string,
    updateUser: propTypes.func.isRequired,
};

export default EmailEditButton;
