import React, { useState, useEffect } from 'react';
import {
    Layout,
    Space,
    Spin,
    Modal,
    Typography,
    Button,
    Popover
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { blue, yellow, green, orange, purple} from '@ant-design/colors';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css"
import AddNewCampaignPlan from '../../AddMenu/AddNewCampaignPlan';
import EditCampaignPlan from '../../AddMenu/EditCampaignPlan';
import DeleteCampaignPlansButton from './DeleteCampaignPlansButton';
import CalendarDatePicker from '../CalendarDatePicker';
import { useQuery } from '@apollo/client';
import { GET_CAMPAIGN_PLANS } from '../../../queries';
import moment from 'moment';
import 'moment/locale/ja'; 
import { getFormattedDate } from '../../../Utilities/date';
moment.locale('ja', {
    week: {
        dow: 1,
        doy: 1
    }
});
const localizer = momentLocalizer(moment);

export const localTimeZoneDate = (yyyymmdd) => {
    const m = moment(yyyymmdd);
    return new Date(
        m.year(),
        m.month(),
        m.date(),
        m.hour(),
        m.minute(),
        m.second(),
        m.millisecond()
    );
};
export const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: '2px',
      opacity: 0.8,
      color: 'black',
      border: '0px',
      display: 'block'
    };
    return {
      style: style
    };
};

const CampaignSchedule = () => {
    const [addingPlan, setAddingPlan] = useState(false);
    const [editingPlan, setEditingPlan] = useState(false);
    const [events, setEvents] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [moreDate, setMoreDate] = useState(null);
    const [eventForDate, setEventForDate] = useState(null);
    const [selectedCampaignPlan, setSelectedCampaignPlan] = useState(null);
    const [selectedDate, setSelectedDate] = useState(
        localStorage.getItem('campaignCalendarDate') ? new Date(localStorage.getItem('campaignCalendarDate')) : new Date()
    );
    const { data, loading, refetch } = useQuery(GET_CAMPAIGN_PLANS);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    };

    

    const getColor = (campaignCategoryId) => {
        const colors = [blue, green, orange, purple, yellow];
        return colors[campaignCategoryId - 1][2]
    };

    const getFormattedDateRange = (start, end) => {
        const startMonth = start.getMonth() + 1;
        const startDay = start.getDate();
        const endMonth = end.getMonth() + 1;
        const endDay = end.getDate();
        return `${startMonth}月${startDay}日 ~ ${endMonth}月${endDay}日`;
    };

    const modalHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography.Text strong>{selectedCampaignPlan?.campaignName}</Typography.Text>
            <Space style={{ marginRight: 30 }}>
                <Button icon={<EditOutlined/>} data-testid='campaign-plan-edit-btn' onClick={() => { setEditingPlan(true); hideModal(); }}/>
                <DeleteCampaignPlansButton 
                    campaignPlanIds={selectedCampaignPlan?.id}
                    handleCompleted={ () => { hideModal(); refetch(); }}
                />
            </Space>
        </div>
    );

    useEffect(() => {
        if (data) {
            const eventsList = JSON.parse(data.allCampaignPlans).map(event => {
                const start = localTimeZoneDate(event.start_dt.slice(0,10));
                const end = localTimeZoneDate(event.end_date.slice(0,10));
                const campaignCategoryId = parseInt(event.campaign.campaign_category_id);
                const color = getColor(campaignCategoryId);
                const campaignName = event.campaign.campaign_name;
                const campaignId = event.campaign.id
                const presentName = event.campaign.present_name;
                const formattedRange = getFormattedDateRange(start, end);
                const items = event.items;
                const itemText = () => {
                    if (items.length === 1) {
                        return items[0].item_name
                    } else if (items.length > 1) {
                        let text = '';
                        items.forEach((item, index) => {
                            if (index === items.length - 1) {
                                text += item.item_name;
                            } else {
                                text += `${item.item_name}＋`;
                            }
                        });
                        return text;
                    }
                }
                const title = presentName ? `${campaignName} - ${presentName}` : `${itemText()} / ${campaignName}`
                return {
                    id: event.id,
                    allDay: true,
                    title,
                    start,
                    end,
                    color,
                    campaignName,
                    campaignId,
                    presentName,
                    formattedRange,
                    items
                };
            });
            setEvents(eventsList);
        }
    }, [data]);
    
    const content = (
        <div style={{ width: 300 }}>
            {eventForDate?.map(event => (
                <div key={event.id} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                    <Typography.Text 
                        style={{backgroundColor: event.color, borderRadius: '2px', padding: '.1rem'}}
                        onClick={() => { setSelectedCampaignPlan(event); showModal(); }}
                    >
                            {event.title}
                    </Typography.Text>
                </div>))}
        </div>)

    return <Layout style={{ padding: 24, background: '#fff' }}>
        
            {loading ?
                <Spin /> :
                addingPlan ?
                    <AddNewCampaignPlan setAddingPlan={setAddingPlan} refetch={refetch} /> :
                    editingPlan ?
                        <EditCampaignPlan setEditingPlan={setEditingPlan} refetch={refetch} campaignPlan={selectedCampaignPlan} /> : (
                        <Space direction='vertical' style={{ textAlign: 'center' }}>
                            <span  data-testid='calender_to_pdf'>
                                <Calendar
                                    data-testid='calendar-event'
                                    localizer={localizer}
                                    views={['month']}
                                    events={events}
                                    selectable
                                    date={selectedDate}
                                    endAccessor={(event) => {
                                        const endDate = moment(event.end)
                                        return endDate.add(1, 'days')
                                    }}
                                    defaultView='month'
                                    style={{ height: 1100 }}
                                    onSelectEvent={event => { setSelectedCampaignPlan(event); showModal(); }}
                                    onShowMore={(target, date) => { setEventForDate(target); setMoreDate(date); }}
                                    messages={{ showMore: (target) => <Popover content={content} trigger="click" title={moreDate ? getFormattedDate(moreDate) : null}>+{target} more</Popover> }}
                                    eventPropGetter={eventStyleGetter}
                                    components={{
                                        toolbar: ({onNavigate}) => <CalendarDatePicker
                                                                        onNavigate={onNavigate}
                                                                        setAddingPlan={setAddingPlan}
                                                                        selectedDate={selectedDate}
                                                                        setSelectedDate={setSelectedDate}
                                                                        isCampaignSchedule={true}
                                                                        isFowardingOffSchedule={false}
                                                                        events={events}
                                                                    />
                                    }}
                                />
                            </span>
                        </Space>)
            }
        { selectedCampaignPlan &&
            <Modal 
                visible={isModalVisible}
                onCancel={hideModal}
                title={modalHeader}
                footer={null}
                data-testid='campaign-plan-modal'
            >
                <p>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography.Text strong style={{ width: 140 }}>キャンペーン期間:</Typography.Text>
                        <Typography.Text>{selectedCampaignPlan.formattedRange}</Typography.Text>
                    </div>
                </p>
                <p>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography.Text strong style={{ width: 140 }}>商品名:</Typography.Text>
                        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
                            {
                                selectedCampaignPlan.items.map(item => (
                                    <li key={item.id}>{item.item_name}</li>
                                ))
                            }
                        </ul>
                    </div>
                </p>
            </Modal>
        }
    </Layout>
};

export default CampaignSchedule;
